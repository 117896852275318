<template>
  <div class="scoringRuleModule">
    <div class="block_module">
      <div class="m-title fans">成绩权重分配：总比例=100%</div>
      <div class="m-cont achievement">
        <div class="a_pub">
          <div class="achieve">平时成绩</div>
          <el-input
            v-model.number="editData.usualPerformance"
            maxlength="3"
            onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
            oninput="value=value.replace(/[^\d]/g,'')"
            @blur="checkNum('performance', 'usualPerformance', $event)"
          ></el-input>
          <div>%</div>
        </div>
        <div class="a_pub">
          <div class="achieve">期末成绩</div>
          <el-input
            v-model.number="editData.finalPerformance"
            maxlength="3"
            onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
            oninput="value=value.replace(/[^\d]/g,'')"
            @blur="checkNum('performance', 'finalPerformance', $event)"
          ></el-input>
          <div>%</div>
        </div>
        <div class="a_pub">
          <div class="achieve">其他成绩</div>
          <el-input
            v-model.number="editData.otherPerformance"
            maxlength="3"
            onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
            oninput="value=value.replace(/[^\d]/g,'')"
            @blur="checkNum('performance', 'otherPerformance', $event)"
          ></el-input>
          <div>%</div>
        </div>
      </div>
    </div>
    <div
      class="block_module"
      :style="[
        {
          borderBottom:
            editData.isUalPerformance == 0
              ? 'none'
              : '1px solid #efefef',
        },
      ]"
    >
      <div class="m-cont">
        <span>是否启用平时成绩分配规则：</span>
        <el-radio-group
          v-model="editData.isUalPerformance"
        >
          <el-radio :label="0">否</el-radio>
          <el-radio :label="1">是</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="block_module" v-if="editData.isUalPerformance == 1">
      <div class="m-title fans">平时成绩分配：总分=100分</div>
      <div class="m-cont">
        <el-form
          :inline="true"
          :model="editData"
          class="demo-rule-form"
          label-width="100px"
        >
          <el-form-item label="学习登录:">
            <div class="a_pub_new">
              <div class="achieve">最高</div>
              <el-input
                v-model.number="editData.loginItem.maxScore"
                maxlength="3"
                onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                oninput="value=value.replace(/[^\d]/g,'')"
                @blur="checkNum('maxScore', 'loginItem', $event)"
              ></el-input>
              <div>分</div>
            </div>
            <div class="a_pub_new">
              <div class="achieve">每次</div>
              <el-input
                v-model.number="editData.loginItem.value"
                maxlength="3"
                onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                oninput="value=value.replace(/[^\d]/g,'')"
                @blur="checkNum('value', 'loginItem', $event)"
              ></el-input>
              <div>分</div>
            </div>
          </el-form-item>
          <el-form-item label="课件学习:">
            <div class="a_pub_new">
              <div class="achieve">最高</div>
              <el-input
                maxlength="3"
                onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                oninput="value=value.replace(/[^\d]/g,'')"
                v-model.number="editData.courseStudy.maxScore"
                @blur="checkNum('maxScore', 'courseStudy', $event)"
              ></el-input>
              <div>分</div>
            </div>
            <div class="a_pub_new">
              <div class="achieve">{{scoreflag == 1 ?'连续学习':'累计学习'}}</div>
              <el-input
                maxlength="3"
                onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                oninput="value=value.replace(/[^\d]/g,'')"
                v-model.number="editData.courseStudy.minute"
                @blur="checkNum('minute', 'courseStudy', $event)"
              ></el-input>
              <div>分钟</div>
            </div>
            <div class="a_pub_new">
              <div class="achieve">得</div>
              <el-input
                maxlength="3"
                onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                oninput="value=value.replace(/[^\d]/g,'')"
                v-model.number="editData.courseStudy.value"
                @blur="checkNum('value', 'courseStudy', $event)"
              ></el-input>
              <div>分</div>
            </div>
          </el-form-item>
          <el-form-item label="直播学习:">
            <div class="a_pub_new">
              <div class="achieve">最高</div>
              <el-input
                maxlength="3"
                onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                oninput="value=value.replace(/[^\d]/g,'')"
                v-model.number="editData.liveStudy.maxScore"
                @blur="checkNum('maxScore', 'liveStudy', $event)"
              ></el-input>
              <div>分</div>
            </div>
            <div class="a_pub_new">
              <div class="achieve">连续学习</div>
              <el-input
                maxlength="3"
                onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                oninput="value=value.replace(/[^\d]/g,'')"
                v-model.number="editData.liveStudy.minute"
                @blur="checkNum('minute', 'liveStudy', $event)"
              ></el-input>
              <div>分钟</div>
            </div>
            <div class="a_pub_new">
              <div class="achieve">得</div>
              <el-input
                maxlength="3"
                onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                oninput="value=value.replace(/[^\d]/g,'')"
                v-model.number="editData.liveStudy.value"
                @blur="checkNum('value', 'liveStudy', $event)"
              ></el-input>
              <div>分</div>
            </div>
          </el-form-item>
          <el-form-item label="教辅材料学习:">
            <div class="a_pub_new">
              <div class="achieve">最高</div>
              <el-input
                maxlength="3"
                onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                oninput="value=value.replace(/[^\d]/g,'')"
                v-model.number="editData.ebookStudy.maxScore"
                @blur="checkNum('maxScore', 'ebookStudy', $event)"
              ></el-input>
              <div>分</div>
            </div>
            <div class="a_pub_new">
              <div class="achieve">连续学习</div>
              <el-input
                maxlength="3"
                onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                oninput="value=value.replace(/[^\d]/g,'')"
                v-model.number="editData.ebookStudy.minute"
                @blur="checkNum('minute', 'ebookStudy', $event)"
              ></el-input>
              <div>分钟</div>
            </div>
            <div class="a_pub_new">
              <div class="achieve">得</div>
              <el-input
                maxlength="3"
                onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                oninput="value=value.replace(/[^\d]/g,'')"
                v-model.number="editData.ebookStudy.value"
                @blur="checkNum('value', 'ebookStudy', $event)"
              ></el-input>
              <div>分</div>
            </div>
          </el-form-item>
          <el-form-item label="提问答疑:">
            <div class="a_pub_new">
              <div class="achieve">最高</div>
              <el-input
                maxlength="3"
                onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                oninput="value=value.replace(/[^\d]/g,'')"
                v-model.number="editData.interactive.maxScore"
                @blur="checkNum('maxScore', 'interactive', $event)"
              ></el-input>
              <div>分</div>
            </div>
            <div class="a_pub_new">
              <div class="achieve">发起评论</div>
              <el-input
                maxlength="3"
                onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                oninput="value=value.replace(/[^\d]/g,'')"
                v-model.number="editData.interactive.value"
                @blur="checkNum('value', 'interactive', $event)"
              ></el-input>
              <div>分/次</div>
            </div>
          </el-form-item>
          <el-form-item label="课程作业:">
            <div class="a_pub_new">
              <div class="achieve">最高</div>
              <el-input
                maxlength="3"
                onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                oninput="value=value.replace(/[^\d]/g,'')"
                v-model.number="editData.work.maxScore"
                @blur="checkNum('maxScore', 'work', $event)"
              ></el-input>
              <div>分</div>
            </div>
            <div class="a_pub_new">
              <div class="achieve">每次</div>
              <el-input
                maxlength="3"
                onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                oninput="value=value.replace(/[^\d]/g,'')"
                v-model.number="editData.work.value"
                @blur="checkNum('value', 'work', $event)"
              ></el-input>
              <div>分</div>
            </div>
          </el-form-item>
          <el-form-item label="学习中心评分:">
            <div class="a_pub_new">
              <div class="achieve">最高</div>
              <el-input
                maxlength="3"
                v-model.number="editData.stations.maxScore"
                onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                oninput="value=value.replace(/[^\d]/g,'')"
                @blur="checkNum('maxScore', 'stations', $event)"
              ></el-input>
              <div>分</div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="error-tip" v-if="!toPass">填写项异常，请确认后保存</div>
    </div>
    <div class="block_module final">
      <div style="color: red">注：</div>
      <div class="tips">
        <p>1、已结束课程的得分规则不会被修改；</p>
        <p>
          2、课件与教辅材料学习连续学习的得分时间一般设置为2-5分钟，以避免学生学习完成后不得分；
        </p>
        <p>3、不同授课方式请设置不同得分规则，避免学生学习完成后不得分</p>
      </div>
    </div>
    <div class="rule_btn_area">
      <el-button type="primary" @click="enterEvent">确 定</el-button>
      <!-- <el-button @click="dialogDiyEvent">取 消</el-button> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'scoringRuleModule',
  props: ['editData', 'scoreflag'],
  data () {
    return {
      // editData: {
      //   courseName: null,
      //   courseStudy: {
      //     maxScore: 0,
      //     minute: 0,
      //     value: 0
      //   },
      //   ebookStudy: {
      //     maxScore: 0,
      //     minute: 0,
      //     value: 0
      //   },
      //   finalPerformance: 0,
      //   id: 0,
      //   interactive: {
      //     maxScore: 0,
      //     minute: 0,
      //     value: 0
      //   },
      //   isUalPerformance: 0,
      //   liveStudy: {
      //     maxScore: 0,
      //     minute: 0,
      //     value: 0
      //   },
      //   loginItem: {
      //     maxScore: 0,
      //     minute: 0,
      //     value: 0
      //   },
      //   otherPerformance: 0,
      //   scoreRule: null,
      //   stations: {
      //     maxScore: 0,
      //     minute: 0,
      //     value: 0
      //   },
      //   teachingMethod: null,
      //   teachingPlanName: null,
      //   usualPerformance: 0,
      //   work: {
      //     maxScore: 0,
      //     minute: 0,
      //     value: 0
      //   }
      // },
      toPass: true,
    }
  },
  methods: {
    // 数据检测是否合格
    checkNum(type, row, $event) {
      if (type == "performance") {
        this.editData[row] = $event.target.value;
        let total =
          Number(this.editData.usualPerformance) +
          Number(this.editData.finalPerformance) +
          Number(this.editData.otherPerformance);
        if (total > 100) {
          this.Warn("成绩权重分配总比例不符合100%,请查验后重试");
        }
        if ($event.target.value == "") {
          this.$nextTick(() => {
            this.editData[row] = 0;
          });
        }
      } else if (type == "maxScore") {
        // 判断平时成绩是否超过100
        this.editData[row].maxScore = $event.target.value;
        let total =
          Number(this.editData.loginItem.maxScore) +
          Number(this.editData.courseStudy.maxScore) +
          Number(this.editData.liveStudy.maxScore) +
          Number(this.editData.ebookStudy.maxScore) +
          Number(this.editData.interactive.maxScore) +
          Number(this.editData.stations.maxScore) +
          Number(this.editData.work.maxScore);
        if (total > 100) {
          this.Warn("平时成绩总分不符合100分，请查验后重试");
        }
        // 判断每次分数是否超过最高分，如果超过就改变每次分数
        if (
          Number(this.editData[row].maxScore) < Number(this.editData[row].value)
        ) {
          this.editData[row].value = Number(this.editData[row].maxScore);
        }
        if ($event.target.value == "") {
          // this.editData[row].value = Number(0);
          this.$nextTick(() => {
            this.$set(this.editData[row], "maxScore", 0);
          });
        }
      } else if (type == "value") {
        this.editData[row].value = Number($event.target.value);
        if (Number(this.editData[row].maxScore) < Number($event.target.value)) {
          this.$nextTick(() => {
            this.$set(
              this.editData[row],
              "value",
              Number(this.editData[row].maxScore)
            );
          });
          return;
        } else {
          this.editData[row].value = Number($event.target.value);
        }
      } else if (type == "minute") {
        this.editData[row].minute = Number($event.target.value);
        if (!$event.target.value) {
          this.$nextTick(() => {
            this.$set(this.editData[row], "minute", 0);
          });
          return;
        } else {
          this.editData[row].minute = Number($event.target.value);
        }
      }
    },
    enterEvent() {
      let total =
        Number(this.editData.usualPerformance) +
        Number(this.editData.finalPerformance) +
        Number(this.editData.otherPerformance);
      if (total > 100) {
        this.Warn("成绩权重分配总比例不符合100%,请查验后重试");
        return;
      }
      if (this.editData.isUalPerformance == 1) {
        this.toPass = true
        for (let key in this.editData) {
          if (this.editData[key] instanceof Object && this.editData[key].maxScore) {
            this.toPass = this.editData[key].value ? true : false
          }
        }
        if (!this.toPass) return;

        let total2 =
          Number(this.editData.loginItem.maxScore) +
          Number(this.editData.courseStudy.maxScore) +
          Number(this.editData.liveStudy.maxScore) +
          Number(this.editData.ebookStudy.maxScore) +
          Number(this.editData.interactive.maxScore) +
          Number(this.editData.stations.maxScore) +
          Number(this.editData.work.maxScore);
        if (total2 > 100) {
          this.Warn("平时成绩总分不符合100分，请查验后重试");
          return;
        }
      }
      let val = JSON.parse(JSON.stringify(this.editData));
      this.$parent.scoringRuleSet(val)
    },
  }
}
</script>

<style lang="less" scoped>
  .scoringRuleModule {
    .block_module {
      border-bottom: 1px solid #efefef;
      .m-title {
        color: #363840;
        font-size: 15px;
      }
      .m-cont {
        font-size: 14px;
        padding: 14px 0;
      }
      .achievement {
        display: flex;
        justify-content: space-between;
      }
      .a_pub {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .achieve {
          width: 64px;
        }
        div:nth-of-type(3) {
          margin-left: 6px;
        }
      }
      .a_pub_new {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-right: 6px;
        .achieve {
          display: inline-block;
        }
        div:nth-of-type(3) {
          margin-left: 6px;
        }
      }
      .fans {
        margin-top: 20px;
      }
      /deep/ .el-form-item {
        display: block;
        display: flex;
        .el-form-item__content {
          display: block;
          display: flex;
        }
      }
      /deep/ .el-input {
        width: 50px;
        .el-input__inner {
          width: 50px;
          padding: 0 8px;
        }
      }
    }
    .final {
      display: flex;
      font-size: 12px;
      color: #363840;
      border-bottom: 1px solid transparent;
      padding: 20px 0;
    }
    .rule_btn_area {
      text-align: right;
      /deep/ .el-button {
        width: 70px;
        height: 34px;
        font-size: 15px;
        padding: 0;
      }
    }
    .error-tip {
      color: red;
      margin-bottom: 10px;
    }
  }
</style>
<template>
  <div class="textMessage">
    <el-table :data="tableData" stripe border style="width: 100%" align="center" row-key="id" ref="multipleTable">
      <el-table-column v-for="(item, index) in columnList" :key="index" :prop="item.prop"
        :label="item.label ? item.label : '/'" :width="item.width" align="center">
        <template slot-scope="scope">
          <template v-if="scope.column.property === 'msgName'">
            <span>{{ $common.msgTypeData[scope.row.type] }}</span>
          </template>
          <span v-else-if="scope.row[scope.column.property] == null">/</span>
          <template v-else-if="scope.column.property === 'type'">
            <span>{{ scope.row.type == 4 ? '验证码' : '通知短信' }}</span>
          </template>
          
          <template v-else-if="scope.column.property === 'msg' || scope.column.property === 'signName'">
            <el-tooltip :content="scope.row[scope.column.property]" placement="top" effect="light"
              popper-class="pubToop">
              <div class="ellipsis">
                {{ scope.row[scope.column.property] || "/" }}
              </div>
            </el-tooltip>
          </template>
          <span v-else>
            {{ scope.row[scope.column.property] }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="120">
        <template slot-scope="scope">
          <el-button @click="editEvent(scope.row)" type="text" size="small">修改</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 修改 -->
    <dialogDiyNew :isShow="isShowEdit" :dialogDiyData="dialogEditDiyData" @dialogEvent="dialogEditEvent">
      <div slot="dialogContent" class="edit_area">
        <el-form
          :inline="true"
          :model="formInline"
          class="demo-form-inline"
          label-width="100px"
        >
          <el-form-item label="短信名称">
            <el-input
              v-model="msgName"
              placeholder="平台名称"
              clearable
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="短信类型">
            <el-input
              v-model="msgType"
              placeholder="短信类型"
              clearable
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="签名">
            <el-input
              v-model="formInline.signName"
              placeholder="签名"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="模板code">
            <el-input
              v-model="formInline.code"
              placeholder="模板code"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="短信内容">
            <el-input
              type="textarea"
              placeholder="请输入内容"
              :autosize="{ minRows: 9 }"
              v-model="formInline.msg"
              maxlength="200"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="btn_area">
          <el-button @click="dialogEditEvent">取消</el-button>
          <el-button type="primary" @click="enterEvent">确认</el-button>
        </div>
      </div>
    </dialogDiyNew>
  </div>
</template>

<script>
import dialogDiyNew from 'components/commonModule/dialogDiyNew'
export default {
  name: 'textMessage',
  components: {
    dialogDiyNew
  },
  data() {
    return {
      tableData: [],
      columnList: [
        {
          prop: "msgName",
          label: "短信名称"
        },
        {
          prop: "signName",
          label: "签名"
        },
        {
          prop: "type",
          label: "短信类型"
        },
        {
          prop: "code",
          label: "模板code"
        },
        {
          prop: "msg",
          label: "短信内容"
        }
      ],
      isShowEdit: false,
      dialogEditDiyData: {
        title: '短信模板修改',
        sizeStyle: {width: '500px',height: '650px'}
      },
      formInline: {},
      msgName: null,
      msgType: null,
    }
  },
  mounted() {
    this.textMessageGet()
  },
  methods: {
    textMessageGet() {
      this.$request.textMessageGet({tenantId: this.$route.query.tenantId}).then(res => {
        if (res.data.code == 0) {
          this.tableData = res.data.data
        }
      })
    },
    dialogEditEvent() {
      this.isShowEdit = !this.isShowEdit
    },
    editEvent(row) {
      this.msgName = this.$common.msgTypeData[row.type]
      this.msgType = row.type == 4 ? '验证码' : '通知短信'
      this.$set(this, 'formInline', row)
      this.dialogEditEvent()
    },
    enterEvent() {
      this.$request.textMessageSet(this.formInline).then(res => {
        if (res.data.code == 0) {
          this.Success(res.data.msg || '操作成功')
          this.dialogEditEvent()
          this.textMessageGet()
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
  .textMessage {
    background-color: #fff;
    box-sizing: border-box;
    padding: 20px;
    .edit_area {
      box-sizing: border-box;
      padding: 20px;
      /deep/ .el-input {
        width: 152%;
      }
      /deep/ .el-textarea {
        width: 330px;
      }
    }
    .btn_area {
      text-align: center;
    }
  }
</style>
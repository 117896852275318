<template>
  <div class="scoringRule">
    <scoringRuleModule :scoreflag="scoreflag" :editData="editData"></scoringRuleModule>
  </div>
</template>

<script>
import scoringRuleModule from 'components/commonModule/scoringRuleModule'
export default {
  name: 'scoringRule',
  components: {
    scoringRuleModule
  },
  mounted () {
    this.scoringRuleGet()
    this.getScoreFlag()
  },
  data() {
    return {
      editData: {
        courseName: null,
        courseStudy: {
          maxScore: 0,
          minute: 0,
          value: 0
        },
        ebookStudy: {
          maxScore: 0,
          minute: 0,
          value: 0
        },
        finalPerformance: 0,
        id: 0,
        interactive: {
          maxScore: 0,
          minute: 0,
          value: 0
        },
        isUalPerformance: 0,
        liveStudy: {
          maxScore: 0,
          minute: 0,
          value: 0
        },
        loginItem: {
          maxScore: 0,
          minute: 0,
          value: 0
        },
        otherPerformance: 0,
        scoreRule: null,
        stations: {
          maxScore: 0,
          minute: 0,
          value: 0
        },
        teachingMethod: null,
        teachingPlanName: null,
        usualPerformance: 0,
        work: {
          maxScore: 0,
          minute: 0,
          value: 0
        }
      },
      scoreflag: 1,
    }
  },
  methods: {
    // 获取学校端招生设置-课件得分计算方式
    getScoreFlag() {
      this.$request.getScoreFlag({tenantId: this.$route.query.tenantId}).then(res => {
        if (res.data.code == 0) {
          this.scoreflag = res.data.data
        }
      })
    },
    scoringRuleGet() {
      this.$request.scoringRuleGet({tenantId: this.$route.query.tenantId}).then(res => {
        if (res.data.code == 0) {
          this.editData = res.data.data
        }
      })
    },
    scoringRuleSet(val) {
      let obj = {
        tenantId: this.$route.query.tenantId,
        vo: val
      }
      this.$request.scoringRuleSet(obj).then(res => {
        if (res.data.code == 0) {
          this.Success(res.data.msg || '操作成功')
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
  .scoringRule {
    background-color: #fff;
    box-sizing: border-box;
    padding: 0 40px 20px;
    overflow: hidden;
    .scoringRuleModule {
      width: 600px;
    }
  }
</style>
<template>
  <div class="schoolWay">
    <div class="schoolWay-head">招生设置</div>
    <div class="schoolWay-item">
      <div class="schoolWay-title">招生管理电话加密</div>
      <div class="schoolWay-cont">
        <el-radio-group v-model="form.aidDesensitizationFlag" @change="UpdateInfo">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="schoolWay-item">
      <div class="schoolWay-title">招生争议配置</div>
      <div class="schoolWay-cont">
        <el-switch
          v-model="form.aidDisputeFlag"
          active-color="#1A7EE2"
          inactive-color="#dcdfe6"
          :active-value="1"
          :inactive-value="0"
          @change="UpdateInfo"
        >
        </el-switch>
        <span>关闭后，学生归属教学点采取优先录取规则</span>
      </div>
    </div>
    <div class="schoolWay-head s-head">登录设置</div>
    <div class="schoolWay-item">
      <div class="schoolWay-title">账号密码登录滑块验证</div>
      <div class="schoolWay-cont">
        <el-switch
          v-model="form.sliderVerifyFlag"
          active-color="#1A7EE2"
          inactive-color="#dcdfe6"
          :active-value="1"
          :inactive-value="0"
          @change="UpdateInfo"
        >
        </el-switch>
        <span>关闭后，学生输入账号密码后直接登录</span>
      </div>
    </div>
    <div class="schoolWay-head s-head">学校后台显示设置</div>
    <div class="schoolWay-item">
      <div class="schoolWay-title">层次显示</div>
      <div class="schoolWay-cont">
        <el-checkbox-group v-model="form.eduCategoryList" @change="UpdateInfo">
          <el-checkbox :label="2">高起专</el-checkbox>
          <el-checkbox :label="1">高起本</el-checkbox>
          <el-checkbox :label="0">专升本</el-checkbox>
          <el-checkbox :label="3">中专</el-checkbox>
          <el-checkbox :label="4">高职</el-checkbox>
        </el-checkbox-group>
      </div>
    </div>
    <div class="schoolWay-head s-head">学生端后台设置</div>
    <div class="schoolWay-item">
      <div class="schoolWay-title">是否隐藏教学点信息</div>
      <div class="schoolWay-cont">
        <el-radio-group v-model="form.stuStationHiddenFlag" @change="UpdateInfo">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="schoolWay-item">
      <div class="schoolWay-title">可在线学习的学籍状态</div>
      <div class="schoolWay-cont">
        <el-checkbox-group v-model="form.learnStudentStatus" @change="UpdateInfo">
          <el-checkbox :label="0">预报名</el-checkbox>
          <el-checkbox :label="1">已录取</el-checkbox>
          <el-checkbox :label="2">在读</el-checkbox>
          <el-checkbox :label="3">保留学籍</el-checkbox>
          <el-checkbox :label="4">预毕业</el-checkbox>
          <el-checkbox :label="5">休学</el-checkbox>
          <el-checkbox :label="6">毕业</el-checkbox>
          <el-checkbox :label="7">退学</el-checkbox>
          <el-checkbox :label="8">取消学籍</el-checkbox>
          <el-checkbox :label="9">肄业</el-checkbox>
          <el-checkbox :label="10">结业</el-checkbox>
          <el-checkbox :label="11">归档</el-checkbox>
        </el-checkbox-group>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'schoolWay',
  data() {
    return {
      form: {
        aidDesensitizationFlag: 0,
        aidDisputeFlag: 1,
        sliderVerifyFlag: 1,
        tenantId: null,
        stuStationHiddenFlag:0,
        eduCategoryList:[],
        learnStudentStatus:[],
      },
    }
  },
  mounted() {
    this.schoolWayGetEvent()
  },
  methods: {
    schoolWayGetEvent() {
      this.$request.schoolWayGet({tenantId: this.$route.query.tenantId}).then(res => {
        if (res.data.code == 0) {
          this.$set(this, 'form', res.data.data)
        }
      })
    },
    changeCheck(value){
      console.log(value);
      console.log(this.eduCategoryList)
      if (this.eduCategoryList.length>0) {
        this.UpdateInfo()
      } else {
        this.Warn("请至少选一个层次显示");
      }
    },
    UpdateInfo() {
      this.$request.schoolWaySet(this.form).then(res => {
        if (res.data.code == 0) {}
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .schoolWay {
    background-color: #fff;
    box-sizing: border-box;
    padding: 20px;
    height: 600px;
    .schoolWay-head {
      font-size: 18px;
      margin: 10px 0;
    }
    .s-head {
      margin-top: 50px;
    }
    .schoolWay-item {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }
    .schoolWay-title {
      width: 160px;
      font-size: 14px;
      margin-right: 10px;
      text-align: right;
    }
    .schoolWay-cont {
      & > span {
        font-size: 14px;
        margin-left: 10px;
      }
    }
  }
</style>
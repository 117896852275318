<template>
  <div class="charge">
    <div class="charge-box">
      <div class="charge-item">
        <div class="charge-item-title">是否开启学校自主收费</div>
        <div class="charge-item-cont">
          <el-radio-group v-model="personalPayFlag" @change="UpdateChargeInfo">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </div>
      </div>
      <template v-if="personalPayFlag == 1">
        <div class="charge-item">
          <div class="charge-item-title">是否开启微信收费</div>
          <div class="charge-item-cont">
            <el-radio-group v-model="weChatPayFlag" @change="UpdateChargeInfo">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </div>
        </div>
        <template v-if="weChatPayFlag == 1">
          <div class="charge-small">
            <div class="charge-small-title">微信支付配置</div>
            <div class="charge-small-cont">
              <div class="charge-item">
                <div class="charge-item-title">Appid</div>
                <div class="charge-item-cont">
                  <el-input
                    placeholder="Appid"
                    v-model="weChatPay.appId"
                    @blur="UpdateChargeInfo"
                    maxlength="50"
                    clearable>
                  </el-input>
                </div>
              </div>
              <div class="charge-item">
                <div class="charge-item-title">Webappid</div>
                <div class="charge-item-cont">
                  <el-input
                    placeholder="Webappid"
                    v-model="weChatPay.webAppId"
                    @blur="UpdateChargeInfo"
                    maxlength="50"
                    clearable>
                  </el-input>
                </div>
              </div>
              <div class="charge-item">
                <div class="charge-item-title">商户号</div>
                <div class="charge-item-cont">
                  <el-input
                    placeholder="商户号"
                    v-model="weChatPay.mchId"
                    @blur="UpdateChargeInfo"
                    maxlength="50"
                    clearable>
                  </el-input>
                </div>
              </div>
              <div class="charge-item">
                <div class="charge-item-title">APlv2密钥</div>
                <div class="charge-item-cont">
                  <el-input
                    placeholder="APlv2密钥"
                    v-model="weChatPay.privateKey"
                    @blur="UpdateChargeInfo"
                    maxlength="50"
                    clearable>
                  </el-input>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class="charge-item">
          <div class="charge-item-title">是否开启支付宝收费</div>
          <div class="charge-item-cont">
            <el-radio-group v-model="aliPayFlag" @change="UpdateChargeInfo">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </div>
        </div>
        <template v-if="aliPayFlag == 1">
          <div class="charge-small">
            <div class="charge-small-title">支付宝支付配置</div>
            <div class="charge-small-cont">
              <div class="charge-item">
                <div class="charge-item-title">Appid</div>
                <div class="charge-item-cont">
                  <el-input
                    placeholder="Appid"
                    v-model="aliPay.appId"
                    @blur="UpdateChargeInfo"
                    maxlength="50"
                    clearable>
                  </el-input>
                </div>
              </div>
              <div class="charge-item">
                <div class="charge-item-title">公钥</div>
                <div class="charge-item-cont">
                  <el-input
                    placeholder="公钥"
                    v-model="aliPay.publicKey"
                    @blur="UpdateChargeInfo"
                    maxlength="50"
                    clearable>
                  </el-input>
                </div>
              </div>
              <div class="charge-item">
            <div class="charge-item-title">私钥</div>
            <div class="charge-item-cont">
              <el-input
                placeholder="私钥"
                v-model="aliPay.privateKey"
                @blur="UpdateChargeInfo"
                maxlength="50"
                clearable>
              </el-input>
            </div>
              </div>
            </div>
          </div>
        </template>
      </template>
      <template>
          <div class="charge-item">
          <div class="charge-item-title">是否对接第三方电子发票</div>
          <div class="charge-item-cont">
            <el-radio-group v-model="electronicInvoiceFlag" @change="UpdateChargeInfo">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </div>
        </div>
        <template v-if="electronicInvoiceFlag == 1">
          <div class="charge-small">
            <div class="charge-small-title">开发票配置</div>
            
            <div class="charge-small-cont">
              <div class="charge-item">
                <div class="charge-item-title">税号</div>
                <div class="charge-item-cont">
                  <el-input
                    placeholder="税号"
                    v-model="invoice.taxNo"
                    maxlength="50"
                    clearable>
                  </el-input>
                </div>
              </div>
              <div class="charge-item">
                <div class="charge-item-title">开具方式</div>
                <div class="charge-item-cont">
                  <el-radio-group v-model="invoice.taxType">
                    <el-radio :label="0">全电</el-radio>
                    <el-radio :label="1">税控</el-radio>
                  </el-radio-group>
                </div>
              </div>
              <div class="charge-item" v-if="invoice.taxType == 1">
                <div class="charge-item-title">设备号</div>
                <div class="charge-item-cont">
                  <el-input
                    placeholder="设备号"
                    v-model="invoice.taxDiskNo"
                    maxlength="50"
                    clearable>
                  </el-input>
                </div>
              </div>
              <div class="charge-item">
                <div class="charge-item-title">开票人</div>
                <div class="charge-item-cont">
                  <el-input
                    placeholder="开票人"
                    v-model="invoice.drawer"
                    maxlength="50"
                    clearable>
                  </el-input>
                </div>
              </div>
              <div class="charge-item">
                <div class="charge-item-title">复核人</div>
                <div class="charge-item-cont">
                  <el-input
                    placeholder="复核人"
                    v-model="invoice.checker"
                    maxlength="50"
                    clearable>
                  </el-input>
                </div>
              </div>
              <div class="charge-item">
                <div class="charge-item-title">收款人</div>
                <div class="charge-item-cont">
                  <el-input
                    placeholder="收款人"
                    v-model="invoice.payee"
                    maxlength="50"
                    clearable>
                  </el-input>
                </div>
              </div>

              <div class="charge-item">
                <div class="charge-item-title">学费商品编码</div>
                <div class="charge-item-cont">
                  <el-input
                    placeholder="学费商品编码"
                    v-model="invoice.tuitionGoodsCode"
                    maxlength="50"
                    clearable>
                  </el-input>
                </div>
              </div>
              <div class="charge-item">
                <div class="charge-item-title">学费商品名称</div>
                <div class="charge-item-cont">
                  <el-input
                    placeholder="学费商品名称"
                    v-model="invoice.tuitionGoodsName"
                    maxlength="50"
                    clearable>
                  </el-input>
                </div>
              </div>
              <div class="charge-item">
                <div class="charge-item-title">学费税率</div>
                <div class="charge-item-cont">
                  <el-select v-model="invoice.tuitionGoodsTaxRate" placeholder="请选择">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </div>
              </div>

              <div class="charge-item">
                <div class="charge-item-title">学杂费商品编码</div>
                <div class="charge-item-cont">
                  <el-input
                    placeholder="学杂费商品编码"
                    v-model="invoice.tuitionAdditionGoodsCode"
                    maxlength="50"
                    clearable>
                  </el-input>
                </div>
              </div>
              <div class="charge-item">
                <div class="charge-item-title">学杂费商品名称</div>
                <div class="charge-item-cont">
                  <el-input
                    placeholder="学杂费商品名称"
                    v-model="invoice.tuitionAdditionGoodsName"
                    maxlength="50"
                    clearable>
                  </el-input>
                </div>
              </div>
              <div class="charge-item">
                <div class="charge-item-title">学杂费税率</div>
                <div class="charge-item-cont">
                  <el-select v-model="invoice.tuitionAdditionGoodsTaxRate" placeholder="请选择">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="charge-item">
                <div class="charge-item-title">联系人</div>
                <div class="charge-item-cont charge-item-cont2">
                  <el-input class="phoneInput" v-model="invoice.drawerPhone" placeholder="请输入联系人" clearable></el-input>
                  <span>（用于接收票务相关短信）</span>
                </div>
              </div>
              <div class="charge-item center">
                <el-button type="primary" @click="UpdateChargeInfo">确认</el-button>
              </div>
            </div>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'charge',
  data() {
    return {
      // chargeObj: {
        aliPay: {
          appId: null,
          mchId: null,
          privateKey: null,
          publicKey: null,
          webAppId: null
        },
        aliPayFlag: 0,
        electronicInvoiceFlag:0,
        personalPayFlag: 0,
        tenantId: 0,
        weChatPay: {
          appId: null,
          mchId: null,
          privateKey: null,
          publicKey: null,
          webAppId: null
        },
        invoice:{
          taxNo:null,
          taxType:0,
          taxDiskNo:null,
          drawer:null,
          checker:null,
          payee: null
        },
        weChatPayFlag: 0,
        options: [
          {
          value: 0.00,
          label: '0%'
        }, 
        {
          value: 0.01,
          label: '1%'
        }, 
        {
          value: 0.03,
          label: '3%'
        }, 
        {
          value: 0.06,
          label: '6%'
        }, 
        {
          value: 0.09,
          label: '9%'
        }, 
        {
          value: 0.11,
          label: '11%'
        }, 
        {
          value: 0.13,
          label: '13%'
        }, 
        {
          value: 0.17,
          label: '17%'
        }
      ],
      // }
    }
  },
  mounted() {
    this.chargeInfo()
  },
  methods: {
    // 获取数据
    chargeInfo() {
      this.$request.schoolPayGet({tenantId: this.$route.query.tenantId}).then(res => {
        if (res.data.code == 0) {
          Object.assign(this, res.data.data)
          // this.$set(this, 'chargeObj', res.data.data)
          // console.log(this.chargeObj,985);
        }
      })
    },
    // 设置
    UpdateChargeInfo() {
      let obj = {
        aliPay: this.aliPay,
        aliPayFlag: this.aliPayFlag,
        personalPayFlag: this.personalPayFlag,
        tenantId: this.tenantId,
        weChatPay: this.weChatPay,
        weChatPayFlag: this.weChatPayFlag,
        electronicInvoiceFlag: this.electronicInvoiceFlag,
        invoice: this.invoice
      }
      this.$request.schoolPaySet(obj).then(res => {
        if (res.data.code == 0) {}
      })
    }
  }
}
</script>

<style lang="less" scoped>
.charge-item-cont2 {
  display: flex;
    flex-flow: nowrap;
    width: 450px;
    align-items: center;
    .phoneInput {
      width: 282px !important;
    }
}
  .charge {
    background-color: #fff;
    box-sizing: border-box;
    padding: 20px;
    .charge-box {
      // width: 500px;
      // margin: 0 auto;
    }
    .charge-item {
      display: flex;
      align-items: center;
      font-size: 14px;
      margin-bottom: 10px;
      &.center {
        justify-content:center;
      }
      .charge-item-title {
        width: 160px;
        text-align: right;
        margin-right: 10px;
        color: #333;
      }
    }
    .charge-small {
      display: flex;
      .charge-small-title {
        font-size: 14px;
        color: #333;
        width: 160px;
        text-align: right;
      }
      .charge-small-cont {
        .charge-item {
          &:nth-of-type(1) {
            margin-top: 20px;
          }
          .charge-item-title {
            width: 100px;
          }
        }
      }
    }
    /deep/ .el-input {
      width: 130%;
    }
  }
</style>
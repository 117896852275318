<template>
  <div class="schoolSet">
    <div class="info_l">
      <ul>
        <li
          :class="['info_li', view == item.view ? 'on' : '']"
          v-for="item in leftInfo"
          :key="item.view"
          @click="tabhandle(item.view)"
        >
          <!-- <img :src="item.img" /> -->
          {{ item.name }}
        </li>
      </ul>
    </div>
    <div class="info_r">
      <component :is="view" :ref="view"></component>
    </div>
  </div>
</template>

<script>
// 批量导入组件
const requireComponent = require.context("/", true, /\w+\.(vue|js)$/);
const cmps = {};
// 组件排除，单独引入
const filterCmps = ["./schoolSet.vue"];
requireComponent.keys().forEach((fileName) => {
  // console.log(fileName);
  let cmp = requireComponent(fileName).default;

  !filterCmps.includes(fileName) && (cmps[cmp.name] = cmp);
  // console.log(cmps);
});

export default {
  name: "schoolSet",
  components: {
    ...cmps,
  },
  props:['formName'],
  data() {
    return {
      leftInfo: [
        {
          name: "收费设置",
          view: "charge",
        },
        {
          name: "得分规则模板设置",
          view: "scoringRule",
        },
        {
          name: "学校功能设置",
          view: "schoolWay",
        },
        {
          name: "短信模板设置",
          view: "textMessage",
        },
      ],
      view: null,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        // console.log(newVal,oldVal)
        if (newVal.name == "schoolSet") {
          this.view = newVal.query.mouduleName || 'charge';
        }
      },
    },
  },
  destroyed() {
    sessionStorage.removeItem('myInfoView')
  },
  created() {
    this.recordHistoryClick()
  },
  methods: {
    tabhandle(val) {
      this.view = val;
      sessionStorage.setItem('myInfoView',val)
    },
    // 点击左边菜单时保存当前点的的路径，刷新的时候根据保存的记录去展示
    recordHistoryClick() {
      if (sessionStorage.getItem('myInfoView')) {
        this.tabhandle(sessionStorage.getItem('myInfoView'))
      }
    }
  },
};
</script>

<style lang="less" scoped>
.schoolSet {
  display: flex;
  .info_l {
    width: 180px;
    height: max-content;
    background: #ffffff;
    border: 1px solid #efefef;
    ul {
      .info_li {
        display: flex;
        align-items: center;
        padding-left: 20px;
        height: 47px;
        margin: 20px 0;
        cursor: pointer;
        img {
          padding-right: 8px;
        }
        &.on {
          border-left: 5px solid #1a7ee2;
          background: #f9f9f9;
        }
      }
    }
  }
  .info_r {
    margin-left: 10px;
    width: 1034px;
    flex: 1;
  }
}
</style>
